* {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  word-wrap: break-word;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  overflow-x: hidden;
}

.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}

nav.navbar {
  margin: 0;
  padding: 0;
  width: 100%;
}

h1 {
  font-weight: 300;
  font-size: 24px;
}

.navbar-text-www {
  margin-bottom: 0;
}

.navbar-social {
  float: right;
}

.navbar-social a {
  margin-left: 20px;
  color: black;
  font-size: 14px;
}

.navbar-text-title {
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.title-image {
  position: relative;
}

.title-image-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFFFFFFF', endColorstr='#FFFFFFFF');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgwODA4MCIvPjxzdG9wIG9mZnNldD0iMyUiIHN0b3AtY29sb3I9IiM4MDgwODAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiM4MDgwODAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjODA4MDgwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #808080), color-stop(3%, rgba(128, 128, 128, 0)), color-stop(97%, rgba(128, 128, 128, 0)), color-stop(100%, #808080));
  background-image: -moz-linear-gradient(top, #808080 0%, rgba(128, 128, 128, 0) 3%, rgba(128, 128, 128, 0) 97%, #808080 100%);
  background-image: -webkit-linear-gradient(top, #808080 0%, rgba(128, 128, 128, 0) 3%, rgba(128, 128, 128, 0) 97%, #808080 100%);
  background-image: linear-gradient(to bottom, #808080 0%, rgba(128, 128, 128, 0) 3%, rgba(128, 128, 128, 0) 97%, #808080 100%);
}

.title-image img {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.nav-meni {
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav-meni div {
  text-align: center;
}

.nav-meni div a {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: black;
  font-size: 14px;
  text-decoration: none;
}

div.nav-meni-selected a {
  font-weight: 700;
}

.main-text {
  font-size: 14px;
  font-weight: 300;
  color: #676665;
}

.main-text h2 {
  font-size: 16px;
  font-weight: 400;
}

.main-text h3 {
  font-size: 15px;
  font-weight: 400;
}

#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

.push {
  clear: both;
  height: 120px;
}

#footer {
  background: #eee;
  height: 80px;
  overflow: hidden;
  margin-top: 40px;
  /*padding-top: 20px;*/
}

#gc-footer {
  background: #eee;
  height: 80px;
  overflow: hidden;
  margin-top: 100px;
  padding-top: -20px;
}

.social-links {
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
}

.social-links a {
  color: black;
  font-size: 14px;
  display: inline-block;
  margin: 0 7px;
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 35px;
  text-align: center;
}

.copyright {
  font-size: 11px;
  text-align: center;
}

.cdk-link {
  color: #003371;
}

.cdk-center {
  text-align: center;
}

video {
  width: 100%;
  height: auto;
}

.cdk-svg {
  font-size: 16px;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  width: 100%;
}

h1.cdk-h1, h2.cdk-h2 {
  display: block;
  font-weight: 400;
  font-size: 24px;
  color: #003371;
  margin-bottom: 24px;
}

h3.cdk-h3 {
  display: block;
  font-weight: 400;
  font-size: 20px;
  color: #003371;
  margin-bottom: 10px;
}

.cdk-single-text {
  padding-top: 40px;
  padding-bottom: 40px;
}

.cdk-first-text {
  padding-top: 70px;
  padding-bottom: 50px;
  font-size: 24px;
  color: #003371;
  text-align: center;
}

.cdk-first-p {
  display: inline-block;
  padding: 20px 25px;
  background: #e7e7e7;
}

.cdk-contact-container {
  display: inline-block;
  width: 600px;
}

.cdk-contact {
  text-align: left;
}

.cdk-center {
  text-align: center;
}

.cdk-right {
  text-align: right;
}

#upit-form {
  width: 100%;
}

.question-container {
  margin-top: 40px;
  text-align: left;
}

.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow, .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.form-group-valid {
  position: relative;
}

.no-valid {
  position: absolute;
  background: #fff;
  border: thin solid #ff8888;
  border-radius: 4px;
  z-index: 100;
  color: #999;
  padding: 5px 10px;
  font-size: 14px;
  bottom: -43px;
  display: none;
}

.no-valid-arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ff8888;
}

.no-valid-arrow-in {
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
}

textarea.question-box {
  margin-top: 16px;
  height: 203px;
}

.question-send {
  text-align: center;
}

.small-visible {
  display: none;
}

.main-text-plus {
  margin-top: 40px;
}

.impressum-main {
  font-size: 16px;
}

.clear {
  clear: both;
}

.imageWrap {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fa-cdkSocial {
  float: right;
  margin-left: 5px;
  margin-bottom: 5px;
  padding-top: 15px;
  color: #676665;
}

.cdksContainer {
  color: #676665;
  margin-bottom: 30px;
  /*padding: 5px;*/
  padding-right: 5px;
}

.cdksContainer a:hover {
  text-decoration: none;
}

.cdksContainerMain {
  position: relative;
  margin: 10px auto;
  /*height: 400px;*/
  width: 550px;
}

.cdkHashMenu {
  /*border-top: 1px solid #eee;*/
  /*background: #f1f1f1;*/
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 25px;
  text-align: center;
}

.cdksOverlayMain {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  width: 550px;
  background-color: #2b2626;
  opacity: .9;
}

.cdksTwTextMain {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  width: 550px;
  padding: 20px;
  padding-top: 20%;
  color: #fff;
  word-wrap: break-word;
}

.cdksTwTextMain img {
  max-width: 48px;
  max-height: 48px;
}

.cdksTitleUser {
  word-wrap: break-word;
}

.cdksTwText {
  color: #676665;
  clear: both;
  padding: 1px;
}

.cdksTwText p {
  margin-bottom: 0px;
}

.cdksTitle img {
  max-width: 48px;
  max-height: 48px;
  word-wrap: break-word;
}

.cdksMainLogo {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
}

.cdksTwPhotoMain {
  text-align: center;
  height: 400px;
  width: 550px;
  overflow: hidden;
  font: 0/0 a;
}

.cdksTitleLogo {
  text-align: right;
  margin-bottom: 5px;
}

.cdksTwPhoto img {
  max-width: 100%;
}

.cdksVerticalHelper {
  height: 100%;
  max-width: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.cdksTwPhotoMain img {
  padding: 0;
  margin: 0;
  max-height: 400px;
  max-width: 550px;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .slick-slider {
    margin-bottom: 50px;
    padding: 0;
  }

  .cdksTwPhoto {
    text-align: center;
  }

  .cdksTwPhoto img {
    width: 94%;
  }
}
.cdksTitle {
  /*font-variant: small-caps;*/
  font-size: 20px;
  line-height: 100%;
  color: #676665;
}

.cdksTitle p {
  padding-top: 15px;
}

/* These are the classes that are going to be applied: */
.column {
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}

.size-1of3 {
  width: 33.333%;
}

.size-1of1 {
  width: 100%;
}

@media all and (max-width: 991px) {
  #fb-grid[data-columns]::before {
    content: '1 .column.size-1of1';
  }

  .small-visible {
    display: block;
  }

  .small-none {
    display: none;
  }

  .cdk-contact-container {
    width: 100%;
  }

  .cdk-svg {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 992px) {
  #fb-grid[data-columns]::before {
    content: '3 .column.size-1of3';
  }
}
